.Logo {
  position:relative; 
  left: 2%; 
  top:10px;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.div1{
  text-align: center;
  position: relative;
  top: 20px;
  font-size: 1.2em;
}

.div2{
  text-align: left;
  line-height: 2;
  font-size: 1.6em;
  position: relative;
  margin: 2.5%;
  margin-top: 5% ;
  float: left;
  font-weight:600;
}

.div3{
  color: #38D059;
  font-size: 2em;
  font-weight: bold;
}

.div4{
  text-align: center;
  font-size: 2em;
  position: relative;
  top: 20px;
  font-weight: 600;
}

.div5{
  background: #38D059;
  width: 175px;
  height: 200px;
  float: left;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0px 10px 8px grey;
  position: relative;
  top: 30px;
  border: none;
}

.div6{
  text-align:center;
  position: relative;
  font-size: 2em;
}

.div7{
  float:left;
  font-size: 1.2em;
  position: relative;
  top: 2%;
  margin: 10px;
}

.div8{
  position: relative;
  font-size: 2em;
  float: left;
  margin-left: 35px;
}

.addAct{
  background: #D9D9D9;
  width: 175px;
  height: 200px;
  float: left;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0px 10px 8px grey;
  border: #D9D9D9;
  position: relative;
  top: 30px;
  text-align:center;
  font-size: 1.6em;
}

.addActTxt{
  margin-top: 10%;
}


.modalContainer {
  width: 500px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: fixed;
  left: 33%;
  right: 33%;
  top: 8em;
  display: flex;
  flex-direction: column;
  padding: 25px;
  text-align: center;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}

.blur {
  filter: blur(5px);
}

.greenButton {
  background-color: #38D059;
  margin: 1em;
  padding-left: 2em;
  padding-right: 2em;
  font-size: 2em;
  border-radius: 8px;
  border:none;
  box-shadow: 0px 2px 3px grey;
}

.redButton {
  background-color: red;
  margin: 1em;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1.8em;
  border-radius: 8px;
  border:none;
  box-shadow: 0px 2px 3px grey;
}

.greyButton {
  background-color: #D9D9D9;
  margin: 1em;
  padding-left: 2em;
  padding-right: 2em;
  font-size: 1.8em;
  border-radius: 8px;
  border:none;
  box-shadow: 0px 2px 3px grey;
}

.titleInput {
  background-color: #D9D9D9;
  border: none;
  border-radius: 8px;
  height: 3em;
  width: 20em;
  margin: 1em;
}

.descInput {
  background-color: #D9D9D9;
  border: none;
  border-radius: 8px;
  height: 10em;
  width: 20em;
  margin: 1em;
}

.divA1 {
  text-align: center;
  font-size: 3em;
  font-weight:600; 
}

.divA2 {
  text-align: center;
  font-size: 2em;
  font-weight:300;
  margin-top: 2%;
}

.divA3 {
  text-align: center;
  font-size: 3em;
  font-weight:300;
}

.mainMap {
  margin: 26px; 
  position: center;
  float: left;
}






.ui.statistics .statistic>.value,.ui.statistic>.value{font-family:'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;font-size:4rem;font-weight:normal;line-height:1em;color:#1b1c1d;text-transform:uppercase;text-align:center}
.ui.statistics .statistic>.label,.ui.statistic>.label{font-family:'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;font-size:1em;font-weight:bold;color:rgba(0, 0, 0, 0.87);text-transform:uppercase;text-align:center}


.ui.statistic{display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;margin:1em 0em;max-width:auto}
.ui.statistic+.ui.statistic{margin:0em 0em 0em 1.5em}

.ui.grey.statistics .statistic>.value,.ui.statistics .grey.statistic>.value,.ui.grey.statistic>.value{color:#767676}

.react-calendar-heatmap{border:2px solid #E0E1E2;border-radius:5px;cursor:default}
.react-calendar-heatmap:hover{border:2px solid #b2b3b3}
.react-calendar-heatmap text{font-size:7px;fill:#b2b3b3}
.react-calendar-heatmap .react-calendar-heatmap-small-text{font-size:5px}
.react-calendar-heatmap .color-empty{fill:#F7F7F7}
.react-calendar-heatmap 
.color-filled{fill:#2DB84B; border-radius: 3px }
.color-unfilled{fill:#D9D9D9}
.heatmap-container{position:relative}
.heatmap-container:hover .heatmap-overlay{opacity:1}
.heatmap-overlay{top:0;bottom:0;left:0;right:0;border-radius:5px;border:2px;border-color:#767676;border-style:solid;height:100%;width:100%;opacity:0;position:absolute;-webkit-transition:.3s ease;transition:.3s ease;background-color:white}
.heatmap-overlay-disabled{top:0;bottom:0;left:0;right:0;height:100%;width:100%;opacity:0;position:absolute}
.heatmap-text{color:#2DB84B;top:50%;margin-top:3px;left:50%;position:relative;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);text-align:center}